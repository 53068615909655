<template>
  <div class="left-panel-list project ai">
    <div class="ai-tab-header-div">
      <div class="ai-header-text details">
        <div class="ai-comp-title-row">
          <div class="ai-comp-title-col">
            <div class="chirp-result-title" @click="console.log(messages)">AI Assistance</div>
            <div class="chirp-result-author">OpenAI</div>
          </div>
          <div class="ai-comp-title-col">
            <div class="btn project-control full-width" v-if="!showSettings"
              @click="showSettings = true;">
              Settings
            </div>
          </div>
        </div>
        <div class="chirp-result-details">OpenAI
          <a href="https://ai.google.dev/" target="_blank" class="google-ai-link">ChatGPT</a>.
          Selected LLM Model is <strong>GPT-4o</strong>
        </div>
      </div>
      <div class="ai-update-form w-form" v-if="showSettings">
        <div style="height: 10px"></div>
        <form class="chirp-search-form-wrapper" style="grid-row-gap: 0;">
          <div class="dropdown-label">API Key:</div>
          <div class="chirp-search-input-wrapper">
            <input class="chirp-search-input w-input" placeholder="Gemini API Key" type="text" v-model="apiKey" />
          </div>
        </form>
        <div class="ai-apli-update-btns-wrapper">
          <div class="btn project-control full-width" @click="updateAiSettings">
            Update
          </div>
          <div class="btn project-control full-width cancel" @click="showSettings = false">
            Cancel
          </div>
        </div>
        <div class="api-updating-loading" v-if="false"></div>
        <br>
      </div>
    </div>

    <div class="ai-tab-header-div" v-if="!showSettings">

      <div class="ai-actions-wrapper">
        <div class="dropdown-input">
          <div class="dropdown-items-wrapper" v-if="
            showHistory" v-click-outside="() => {
              showHistory = false
            }
              ">


            <div class="dropdown-item" v-for="(chat, index) in chatList" :key="index"
              @click="selectChat(chat); showHistory = false;" :title="chat.title">{{ chat.title }}
            </div>
            <div class="dropdown-item" v-if="chatList.length == 0">No Chat History</div>
          </div>
          <div class="dropdown-item" v-if="!selectedChat" @click="showHistory = !showHistory">Unsaved Chat</div>
          <div class="dropdown-item" v-else @click="showHistory = !showHistory" :title="selectedChat.title">{{
            selectedChat.title }}</div>
          <div class="dropdown-button" @click="showHistory = !showHistory">arrow_drop_down</div>
        </div>
        <div class="ai-chat-actions-wrapper">
          <div class="btn project-control icon full-width red" title="Delete This Chat" v-if="selectedChat"
            @click="isDeleting = true">
            delete</div>
          <div class="btn project-control icon full-width disabled" title="No Selected Chat to Delete" v-else>
            delete
          </div>
          <div class="btn project-control icon full-width green" title="Start a New Chat" @click="startNewChat">add
          </div>
        </div>
      </div>
      <div class="ai-actions-wrapper" v-if="isDeleting">
        <div class="btn project-control full-width red" @click="deleteChat()">Delete Chat</div>
        <div class="btn project-control full-width cancel" @click="isDeleting = false">Cancel</div>
      </div>
    </div>
    <div class="ai-tab-chat-wrapper" v-if="!showSettings">
      <div class="ai-ask-buttons">
        <div class="ai-chat-window" ref="scrollContainer">
          <div class="chirp-search-result-group" v-if="messages.length > 0" v-for="(message, index) in messages" :key="index">

            <div class="ai-chat-block-wrapper" v-if="message.role == 'user' && message.parts[0].text">
              <div class="ai-chat-block" v-html="message.parts[0].text">
              </div>
              <div class="ai-image-preview-wrapper" v-if="message.images">
                <div class="ai-image-preview" v-for="(image64, index ) in message.images" :key="index"
                  :style="{ 'background-image': `url(data:image/png;base64,${image64})` }">
                </div>
              </div>
            </div>

            <div class="ai-chat-block-wrapper ai" v-if="message.role == 'model' && message.parts[0].text">
              <Markdown class="ai-chat-block ai" :content="message.parts[0].text" />
              <div class="btn ai-chat-action" @click="branchChat(index + 1)"><span
                  class="icon-span">account_tree</span>Branch</div>
              <div class="ai-response-fader"></div>
            </div>
          </div>
          <div class="ai-chat-block-wrapper ai" v-if="aiThinking">
            <Markdown class="ai-chat-block ai" :content="aiResponse" />
            <div class="ai-loading"></div>
          </div>

          <div class="btn project-control full-width ai-regen"
            v-if="messages.length > 0 && messages[messages.length - 1].role == 'model'" @click="regenerateLastResponse">
            Regenerate Last Response</div>
          <div class="btn-ai-chat-go-to-bottom" v-if="messages.length > 2" @click="scrollToBottom">
            vertical_align_bottom
          </div>
        </div>


      </div>
      <div class="btn project-control full-width red" v-if="aiThinking" @click="stopResponse = true">
        Stop Response
      </div>
      <div class="ai-input-form w-form w-form" @submit="(e) => {
        e.preventDefault();
        askAi(aiPrompt);
        aiPrompt = '';
        scrollToBottom();
      }
        ">
        <div class="ai-image-preview-wrapper">
          <div class="ai-image-preview" v-for="(image64, index ) in promptImages" :key="index"
            :style="{ 'background-image': `url(data:image/png;base64,${image64})` }">
            <div class="ai-image-preview-remove" @click="promptImages.splice(index, 1)">close</div>
          </div>
        </div>
        <form class="chirp-search-form-wrapper">
          <div class="ai-chat-input-wrapper">
            <textarea class="ai-chat-input w-input" placeholder="Chat with AI" v-model="aiPrompt"
              @keydown.enter.prevent="(e) => {
                if (e.shiftKey) {
                  // add a newline character to the textarea content
                  aiPrompt += '\n';
                } else {
                  askAi(aiPrompt);
                  aiPrompt = '';
                  scrollToBottom();
                }
              }" @dragover.prevent @drop.prevent="handleImageDrop"></textarea>
            <div class="ai-chat-icon-wrapper attach">
              <div class="normal-icon">attach_file_add</div>
            </div>
            <div class="ai-chat-icon-wrapper" @click="
              askAi(aiPrompt);
            aiPrompt = '';
            scrollToBottom();
            ">
              <div class="normal-icon">send</div>
            </div>

          </div>
        </form>
      </div>
    </div>
    <div class="ai-api-key-setup" v-if="!apiKey">
      <div class="chirp-page-title">OpenAI API Key</div>
      <div class="vertical-spacer"></div>
      <div class="api-key-instruction">
        Get your Key from <a href="https://platform.openai.com/settings/organization/api-keys" target="_blank"
          class="google-ai-link">Google
          OpenAI API Keys Page</a>
      </div>
      <div>
        <br>
        <form class="chirp-search-form-wrapper" style="grid-row-gap: 0;" @submit="(e) => {
          e.preventDefault()
          initAi()
        }">
          <div class="dropdown-label">OpenAI API Key:</div>
          <div class="chirp-search-input-wrapper">
            <input class="chirp-search-input w-input" placeholder="OpenAI API Key" type="text" v-model="apiKey" />
            <button class="btn save" type="submit"> Refresh </button>
          </div>
        </form>

      </div>

    </div>
  </div>
</template>

<script lang="ts">
import { db } from "@/firebase-app";
import router from "@/router";
import { addDoc, collection, CollectionReference, deleteDoc, doc, DocumentData, DocumentReference, getDocs, onSnapshot, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import { defineComponent, stop } from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import axios from 'axios';
import Markdown from "./Markdown.vue";
import { GenerativeModel, GoogleGenerativeAI } from "@google/generative-ai";
import OpenAI from "openai";

type Message = {
  role: "assistant" | "user" | "system";
  content: string;
}
export default defineComponent({
  name: "AiChat",
  components: {
    Markdown,
  },
  data() {
    return {
      apiKey: "",
      stopResponse: false,
      showSettings: false,
      isSaving: false,
      isDeleting: false,
      showHistory: false,
      showModelsList: false,
      aiThinking: false,
      aiPrompt: "",
      messages: [] as Message[] | any,
      aiResponse: "",
      aiModel: null as unknown as OpenAI,
      selectedChat: null as any,
      chatList: [] as any[],
      firstChatSnapshot: true,
      chatSnapshot: null as any,
      promptImages: [] as any[],
      chat: null as any,
    };
  },
  props: {
    oprions: null as any,
    relation: {
      type: String,
      default: "project"
    },
  },
  async mounted() {
    await this.initAi();
    this.getChatList();
  },
  computed: {
    ...mapState(["aiProvider"]),
    ...mapState("modLogin", ["userData", "user"]),
    ...mapState("modProjects", ["selectedProjectId", "selectedProject"]),
  },
  methods: {
    ...mapMutations("modChirp", ["setSelectedChirp"]),

    async initAi() {
      try {
        if (this.userData && this.userData.geminiApiKey) {
          this.apiKey = this.userData.openAiKey;
          this.aiModel = new OpenAI({
            apiKey: this.apiKey,
            dangerouslyAllowBrowser: true
          });
          

          this.startNewChat()
        }
      } catch (err) {
        console.log(err);
      }
    },

    readImageAsBase64(file: File) {
      const reader = new FileReader();
      reader.onload = () => {
        const convertedBase64 = reader.result
        console.log("Reader Results: ", reader.result)
        const base64 = convertedBase64?.toString()
        this.promptImages.push(base64?.split(',')[1])
        console.log("Reader Results Base64: ", base64?.split(',')[1])
        console.log("Prompt Images: ", this.promptImages)
      };
      reader.readAsDataURL(file);
    },

    handleImageDrop(e: any) {
      const file = e.dataTransfer.files[0];
      if (file.type.startsWith('image/')) {
        this.readImageAsBase64(file);
      }

    },

    scrollToBottom() {
      setTimeout(() => {
        const container: HTMLElement = this.$refs
          .scrollContainer as HTMLElement;
        container.scrollTop = container.scrollHeight;
      }, 200);
    },

    async updateAiSettings() {
      
      
      const projectDoc = doc(db, `users/${this.user.uid}`)
      await updateDoc(projectDoc, {
        ["openAiKey"]: this.apiKey
      })
      this.aiModel = new OpenAI({
        apiKey: this.apiKey,
        dangerouslyAllowBrowser: true,
      });
      this.showSettings = false
    },

    async regenerateLastResponse() {
      const lastMessage = this.messages[this.messages.length - 2].parts[0].text
      this.messages.pop()
      this.messages.pop()
      await this.askAi(lastMessage)
      this.saveChat()
    },

    async getChatList() {
      console.log(`getting chats for user: ${this.user.uid} - project: ${this.selectedProjectId}`)

      let chatCollection = null as unknown as CollectionReference;

      chatCollection = collection(db, `users/${this.user.uid}/projects/${this.selectedProjectId}/aiChats`)

      const q = query(chatCollection, orderBy("created", "desc"), where("provider", "==", "Google Gemini"))
      this.chatSnapshot = onSnapshot(q, (snapshot) => {
        const chatList: DocumentData[] = [];
        snapshot.forEach((doc) => {
          const chat = doc.data();
          chat.id = doc.id;
          chatList.push(chat);
          console.log(`Chats(${this.chatList.length})`, this.chatList)
          console.log(chat)
        });
        this.chatList = chatList;
        if (this.firstChatSnapshot == true) {
          this.selectRecentChat()
          this.firstChatSnapshot = false
        }
      });
    },

    selectChat(chat: any) {

      this.selectedChat = chat;
      this.messages = chat.history
     
      const genAI = new GoogleGenerativeAI(this.apiKey);
      this.aiModel = new OpenAI({
        apiKey: this.apiKey,
        dangerouslyAllowBrowser: true
      });
      

    },

    selectRecentChat() {
      if (this.chatList.length > 0) {
        this.selectChat(this.chatList[0])
      } else {
        this.selectedChat = null
        this.messages = [] as Message[]
        this.startNewChat()
      }
    },

    async saveChat() {
      this.aiThinking = true;

      if (this.selectedChat) { // update selected chat if one is selected
        console.log(`saving chat for the selected chat ${this.selectedChat.title}: `, this.messages);
        const chatDoc = doc(db, `users/${this.user.uid}/projects/${this.selectedProjectId}/aiChats`, this.selectedChat.id);
        await setDoc(chatDoc, {
          ["history"]: this.messages,
        }, { merge: true })
        this.aiThinking = false;
        return
      }

      // create chat name
      const title = await this.createChatTitle()
      // project -> aiChat -> newChat
      const chatCollection = collection(db, `users/${this.user.uid}/projects/${this.selectedProjectId}/aiChats`)

      console.log("Trying to addDoc with data:", {
        history: this.messages,
        title: title,
        created: new Date().getTime(),
        model: 'Gemini',
        provider: this.aiProvider,
      })
      await addDoc(chatCollection, {
        history: this.messages,
        title: title,
        created: new Date().getTime(),
        model: 'Gemini',
        provider: this.aiProvider,
      }).then((result) => {
        console.log('saved chat: ', result)
      }).catch((error) => {
        console.log("error saving chat: ", error)
      })
      this.selectRecentChat();
      this.aiThinking = false;
      console.log("Chat saved with title: ", title)
    },

    async branchChat(index: number) {
      this.aiThinking = true;
      let chatCollection = null as unknown as CollectionReference;
      chatCollection = collection(db, `users/${this.user.uid}/projects/${this.selectedProjectId}/aiChats`)

      await addDoc(chatCollection, {
        history: this.messages.slice(0, index),
        title: `Branched - ${this.selectedChat.title}`,
        created: new Date().getTime(),
        model: "gpt-4o",
      }).then((result) => {
        console.log('saved chat: ', result)
      }).catch((error) => {
        console.log("error saving chat: ", error)
      })
      this.selectRecentChat();
      this.aiThinking = false;
    },

    async startNewChat() {
      if (this.aiModel) {
        const startHistory: never[] = [];
        this.messages = startHistory
        this.selectedChat = null
        try {
          this.chat = await this.aiModel.chat.completions.create({
            model: "gpt-4o",
            messages: this.messages
          });
          console.log("Chat is Ready")
        } catch (error) {
          console.log("Chat is NOT Ready: ", error)
        }
      } else {
        console.log("CHAT DID NOT START")
      }
    },

    async askAi(prompt: string) {
      if (!prompt || prompt == "") {
        return
      }
      this.messages.push(
        {
          role: "user",
          content: prompt,
        }
      )
      try {
        this.chat = await this.aiModel.chat.completions.create({
            model: "gpt-4o",
            messages: this.messages
          });
      } catch (error) {

      }
      this.aiResponse = "...";
      this.stopResponse = false;
      this.aiThinking = true;
      // Note: The only accepted mime types are some image types, image/*.

      let imagePart = null
      let result = null
      if (this.promptImages.length > 0) {
        imagePart = {
          inlineData: {
            data: this.promptImages[0],
            mimeType: 'image/*'
          },
        }
        result = await this.chat.generateContentStream([prompt, imagePart]);
        // Print text as it comes in.
        this.aiResponse = "";
        for await (const chunk of result.stream) {
          const chunkText = chunk.text();
          this.aiResponse += chunkText;
        }
      } else {
        result = await this.aiModel.chat.completions.create({
            model: "gpt-4o",
            messages: this.messages,
            stream: true
          });
        // Print text as it comes in.
        this.aiResponse = "";
        for await (const chunk of result) {
          const chunkText = chunk.choices[0]?.delta?.content || "";
          this.aiResponse += chunkText;
        }
        this.stopResponse = true
        this.messages.push(
          {
            role: "assistant",
            content: this.aiResponse,
              
          }

        )
        console.log("Local Chat History", this.messages)

        await this.saveChat()
      }


      this.aiThinking = false

    },

    async createChatTitle() {
      return this.messages[0].parts[0].text
    },

    async deleteChat() {
      this.isDeleting = true;
      console.log(`deleting the selected chat ${this.selectedChat.title}: `, this.messages);
      let chatDoc = null as unknown as DocumentReference
      chatDoc = doc(db, `users/${this.user.uid}/projects/${this.selectedProjectId}/aiChats`, this.selectedChat.id);

      await deleteDoc(chatDoc);
      if (this.chatList.length == 0) {
        this.startNewChat()
      } else {
        this.selectRecentChat();
      }

      this.isDeleting = false;
    },

  },
});
</script>
