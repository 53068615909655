<template>
  <div class="left-panel-list project">
    <div class="setting-section-title">
      <div class="setting-text">Project Settings</div>
    </div>
    <div class="setting-section-subtitle">
      <div class="settings-subtitle">CDN Managment</div>
    </div>
    <div class="cdn-wrapper">
      <div class="cdn-form w-form">
        <form class="cdn-form-wrapper" @submit="(e) => {
          e.preventDefault();
          addCdn(newCdnUrl); newCdnUrl = ''
        }">
          <div class="cdn-input-wrapper">
            <div class="btn cdn-btn" @click="addCdn(newCdnUrl); newCdnUrl = ''">add</div>
            <div class="cdn-input-gradient-holder">
              <input class="cdn-input w-input" v-model="newCdnUrl" placeholder="Type a CDN URL" type="text">
              <div class="cdn-input-gradient"></div>
            </div>
          </div>

          <div class="cdn-list" v-if="selectedProject.cdnList">
            <div class="cdn-input-container" v-for="(cdn, index) in selectedProject.cdnList" :key="index">
              <div class="cdn-input-wrapper">
                <div class="btn cdn-btn red" @click="cdnToDelete = index">delete</div>
                <div class="cdn-input-gradient-holder">
                  <input class="cdn-input disabled w-input" disabled :value="cdn" type="text">
                  <div class="cdn-input-gradient disabled"></div>
                </div>
              </div>
              <div class="cdn-delete-wrapper" :class="{ open: cdnToDelete == index }">
                <div class="btn cdn-confirm red" @click="deleteCdn(cdn)">Delete</div>
                <div class="btn cdn-confirm" @click="cdnToDelete = -1">Cancel</div>
              </div>
            </div>
            <div class="cdn-list-gradient"></div>
          </div>

        </form>
      </div>
    </div>
    <div class="dropdown-with-label">
      <div class="dropdown-label">Ai Provider:
      </div>
      <div class="dropdown-input full-width">
        <div class="dropdown-items-wrapper" v-if="
          showAiProviderList" v-click-outside="() => {
                showAiProviderList = false
              }
                ">

          <div class="dropdown-item" v-for="(provider, index) in AiProviderList" :key="index"
            @click="setAiProvider(provider); showAiProviderList = false">{{ provider }}
          </div>

        </div>
        <div class="dropdown-item" @click="showAiProviderList = !showAiProviderList">{{ aiProvider }}</div>
        <div class="dropdown-button" @click="showAiProviderList = !showAiProviderList">arrow_drop_down</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { db } from "@/firebase-app";
import router from "@/router";
import { arrayRemove, arrayUnion, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { defineComponent } from "vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default defineComponent({
  name: "ProjectSettings",
  data() {
    return {
      cdnToDelete: null as unknown as Number,
      newCdnUrl: "",
      AiProviderList: ["Local Ollama", "Google Gemini", "OpenAI"],
      showAiProviderList: false
    };
  },

  computed: {
    ...mapState("modLogin", ["user"]),
    ...mapState(["aiProvider"]),
    ...mapState("modProjects", ["selectedProject"]),
  },
  methods: {
    ...mapMutations("modChirp", ["setSelectedChirp"]),
    ...mapMutations(["updatePreview", "setAiProvider"]),
    async deleteCdn(cdn: string) {
      const projectDoc = doc(db, `users/${this.user.uid}/projects/${this.selectedProject.id}`)
      await updateDoc(projectDoc, {
        ["cdnList"]: arrayRemove(cdn)
      })
      this.updatePreview()
      this.cdnToDelete = -1
    },
    async addCdn(cdn: string) {
      if (!cdn.startsWith("https://")) {
        console.log("not a valid url")
      }
      const projectDoc = doc(db, `users/${this.user.uid}/projects/${this.selectedProject.id}`)
      await updateDoc(projectDoc, {
        ["cdnList"]: arrayUnion(cdn)
      })
      this.updatePreview()
      console.log("cdn updated")
    },
  },
});
</script>
