import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "left-panel-list project ai" }
const _hoisted_2 = { class: "ai-tab-header-div" }
const _hoisted_3 = { class: "ai-header-text details" }
const _hoisted_4 = { class: "ai-comp-title-row" }
const _hoisted_5 = { class: "ai-comp-title-col" }
const _hoisted_6 = { class: "ai-comp-title-col" }
const _hoisted_7 = {
  key: 0,
  class: "ai-update-form w-form"
}
const _hoisted_8 = {
  class: "chirp-search-form-wrapper",
  style: {"grid-row-gap":"0"}
}
const _hoisted_9 = { class: "chirp-search-input-wrapper" }
const _hoisted_10 = { class: "ai-apli-update-btns-wrapper" }
const _hoisted_11 = {
  key: 0,
  class: "api-updating-loading"
}
const _hoisted_12 = {
  key: 0,
  class: "ai-tab-header-div"
}
const _hoisted_13 = { class: "ai-actions-wrapper" }
const _hoisted_14 = { class: "dropdown-input" }
const _hoisted_15 = {
  key: 0,
  class: "dropdown-items-wrapper"
}
const _hoisted_16 = ["onClick", "title"]
const _hoisted_17 = {
  key: 0,
  class: "dropdown-item"
}
const _hoisted_18 = ["title"]
const _hoisted_19 = { class: "ai-chat-actions-wrapper" }
const _hoisted_20 = {
  key: 1,
  class: "btn project-control icon full-width disabled",
  title: "No Selected Chat to Delete"
}
const _hoisted_21 = {
  key: 0,
  class: "ai-actions-wrapper"
}
const _hoisted_22 = {
  key: 1,
  class: "ai-tab-chat-wrapper"
}
const _hoisted_23 = { class: "ai-ask-buttons" }
const _hoisted_24 = {
  class: "ai-chat-window",
  ref: "scrollContainer"
}
const _hoisted_25 = {
  key: 0,
  class: "ai-chat-block-wrapper"
}
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = {
  key: 0,
  class: "ai-image-preview-wrapper"
}
const _hoisted_28 = {
  key: 1,
  class: "ai-chat-block-wrapper ai"
}
const _hoisted_29 = ["onClick"]
const _hoisted_30 = {
  key: 1,
  class: "ai-chat-block-wrapper ai"
}
const _hoisted_31 = { class: "ai-image-preview-wrapper" }
const _hoisted_32 = ["onClick"]
const _hoisted_33 = { class: "chirp-search-form-wrapper" }
const _hoisted_34 = { class: "ai-chat-input-wrapper" }
const _hoisted_35 = {
  key: 2,
  class: "ai-api-key-setup"
}
const _hoisted_36 = { class: "chirp-search-input-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: "chirp-result-title",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (console.log(_ctx.messages)))
            }, "AI Assistance"),
            _cache[23] || (_cache[23] = _createElementVNode("div", { class: "chirp-result-author" }, "OpenAI", -1))
          ]),
          _createElementVNode("div", _hoisted_6, [
            (!_ctx.showSettings)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "btn project-control full-width",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.showSettings = true;})
                }, " Settings "))
              : _createCommentVNode("", true)
          ])
        ]),
        _cache[24] || (_cache[24] = _createElementVNode("div", { class: "chirp-result-details" }, [
          _createTextVNode("OpenAI "),
          _createElementVNode("a", {
            href: "https://ai.google.dev/",
            target: "_blank",
            class: "google-ai-link"
          }, "ChatGPT"),
          _createTextVNode(". Selected LLM Model is "),
          _createElementVNode("strong", null, "GPT-4o")
        ], -1))
      ]),
      (_ctx.showSettings)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _cache[26] || (_cache[26] = _createElementVNode("div", { style: {"height":"10px"} }, null, -1)),
            _createElementVNode("form", _hoisted_8, [
              _cache[25] || (_cache[25] = _createElementVNode("div", { class: "dropdown-label" }, "API Key:", -1)),
              _createElementVNode("div", _hoisted_9, [
                _withDirectives(_createElementVNode("input", {
                  class: "chirp-search-input w-input",
                  placeholder: "Gemini API Key",
                  type: "text",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.apiKey) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.apiKey]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", {
                class: "btn project-control full-width",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.updateAiSettings && _ctx.updateAiSettings(...args)))
              }, " Update "),
              _createElementVNode("div", {
                class: "btn project-control full-width cancel",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showSettings = false))
              }, " Cancel ")
            ]),
            false
              ? (_openBlock(), _createElementBlock("div", _hoisted_11))
              : _createCommentVNode("", true),
            _cache[27] || (_cache[27] = _createElementVNode("br", null, null, -1))
          ]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.showSettings)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              (
            _ctx.showHistory)
                ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_15, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chatList, (chat, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "dropdown-item",
                        key: index,
                        onClick: ($event: any) => {_ctx.selectChat(chat); _ctx.showHistory = false;},
                        title: chat.title
                      }, _toDisplayString(chat.title), 9, _hoisted_16))
                    }), 128)),
                    (_ctx.chatList.length == 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, "No Chat History"))
                      : _createCommentVNode("", true)
                  ])), [
                    [_directive_click_outside, () => {
              _ctx.showHistory = false
            }
              ]
                  ])
                : _createCommentVNode("", true),
              (!_ctx.selectedChat)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "dropdown-item",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showHistory = !_ctx.showHistory))
                  }, "Unsaved Chat"))
                : (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: "dropdown-item",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showHistory = !_ctx.showHistory)),
                    title: _ctx.selectedChat.title
                  }, _toDisplayString(_ctx.selectedChat.title), 9, _hoisted_18)),
              _createElementVNode("div", {
                class: "dropdown-button",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showHistory = !_ctx.showHistory))
              }, "arrow_drop_down")
            ]),
            _createElementVNode("div", _hoisted_19, [
              (_ctx.selectedChat)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "btn project-control icon full-width red",
                    title: "Delete This Chat",
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.isDeleting = true))
                  }, " delete"))
                : (_openBlock(), _createElementBlock("div", _hoisted_20, " delete ")),
              _createElementVNode("div", {
                class: "btn project-control icon full-width green",
                title: "Start a New Chat",
                onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.startNewChat && _ctx.startNewChat(...args)))
              }, "add ")
            ])
          ]),
          (_ctx.isDeleting)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createElementVNode("div", {
                  class: "btn project-control full-width red",
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.deleteChat()))
                }, "Delete Chat"),
                _createElementVNode("div", {
                  class: "btn project-control full-width cancel",
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.isDeleting = false))
                }, "Cancel")
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.showSettings)
      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              (_ctx.messages.length > 0)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.messages, (message, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "chirp-search-result-group",
                      key: index
                    }, [
                      (message.role == 'user' && message.parts[0].text)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                            _createElementVNode("div", {
                              class: "ai-chat-block",
                              innerHTML: message.parts[0].text
                            }, null, 8, _hoisted_26),
                            (message.images)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(message.images, (image64, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      class: "ai-image-preview",
                                      key: index,
                                      style: _normalizeStyle({ 'background-image': `url(data:image/png;base64,${image64})` })
                                    }, null, 4))
                                  }), 128))
                                ]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      (message.role == 'model' && message.parts[0].text)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                            _createVNode(_component_Markdown, {
                              class: "ai-chat-block ai",
                              content: message.parts[0].text
                            }, null, 8, ["content"]),
                            _createElementVNode("div", {
                              class: "btn ai-chat-action",
                              onClick: ($event: any) => (_ctx.branchChat(index + 1))
                            }, _cache[28] || (_cache[28] = [
                              _createElementVNode("span", { class: "icon-span" }, "account_tree", -1),
                              _createTextVNode("Branch")
                            ]), 8, _hoisted_29),
                            _cache[29] || (_cache[29] = _createElementVNode("div", { class: "ai-response-fader" }, null, -1))
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                : _createCommentVNode("", true),
              (_ctx.aiThinking)
                ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                    _createVNode(_component_Markdown, {
                      class: "ai-chat-block ai",
                      content: _ctx.aiResponse
                    }, null, 8, ["content"]),
                    _cache[30] || (_cache[30] = _createElementVNode("div", { class: "ai-loading" }, null, -1))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.messages.length > 0 && _ctx.messages[_ctx.messages.length - 1].role == 'model')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: "btn project-control full-width ai-regen",
                    onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.regenerateLastResponse && _ctx.regenerateLastResponse(...args)))
                  }, " Regenerate Last Response"))
                : _createCommentVNode("", true),
              (_ctx.messages.length > 2)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 3,
                    class: "btn-ai-chat-go-to-bottom",
                    onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.scrollToBottom && _ctx.scrollToBottom(...args)))
                  }, " vertical_align_bottom "))
                : _createCommentVNode("", true)
            ], 512)
          ]),
          (_ctx.aiThinking)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "btn project-control full-width red",
                onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.stopResponse = true))
              }, " Stop Response "))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "ai-input-form w-form w-form",
            onSubmit: _cache[20] || (_cache[20] = (e) => {
        e.preventDefault();
        _ctx.askAi(_ctx.aiPrompt);
        _ctx.aiPrompt = '';
        _ctx.scrollToBottom();
      }
        )
          }, [
            _createElementVNode("div", _hoisted_31, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.promptImages, (image64, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "ai-image-preview",
                  key: index,
                  style: _normalizeStyle({ 'background-image': `url(data:image/png;base64,${image64})` })
                }, [
                  _createElementVNode("div", {
                    class: "ai-image-preview-remove",
                    onClick: ($event: any) => (_ctx.promptImages.splice(index, 1))
                  }, "close", 8, _hoisted_32)
                ], 4))
              }), 128))
            ]),
            _createElementVNode("form", _hoisted_33, [
              _createElementVNode("div", _hoisted_34, [
                _withDirectives(_createElementVNode("textarea", {
                  class: "ai-chat-input w-input",
                  placeholder: "Chat with AI",
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.aiPrompt) = $event)),
                  onKeydown: _cache[16] || (_cache[16] = _withKeys(_withModifiers((e) => {
                if (e.shiftKey) {
                  // add a newline character to the textarea content
                  _ctx.aiPrompt += '\n';
                } else {
                  _ctx.askAi(_ctx.aiPrompt);
                  _ctx.aiPrompt = '';
                  _ctx.scrollToBottom();
                }
              }, ["prevent"]), ["enter"])),
                  onDragover: _cache[17] || (_cache[17] = _withModifiers(() => {}, ["prevent"])),
                  onDrop: _cache[18] || (_cache[18] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleImageDrop && _ctx.handleImageDrop(...args)), ["prevent"]))
                }, null, 544), [
                  [_vModelText, _ctx.aiPrompt]
                ]),
                _cache[32] || (_cache[32] = _createElementVNode("div", { class: "ai-chat-icon-wrapper attach" }, [
                  _createElementVNode("div", { class: "normal-icon" }, "attach_file_add")
                ], -1)),
                _createElementVNode("div", {
                  class: "ai-chat-icon-wrapper",
                  onClick: _cache[19] || (_cache[19] = ($event: any) => {
              _ctx.askAi(_ctx.aiPrompt);
            _ctx.aiPrompt = '';
            _ctx.scrollToBottom();
            })
                }, _cache[31] || (_cache[31] = [
                  _createElementVNode("div", { class: "normal-icon" }, "send", -1)
                ]))
              ])
            ])
          ], 32)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.apiKey)
      ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
          _cache[36] || (_cache[36] = _createElementVNode("div", { class: "chirp-page-title" }, "OpenAI API Key", -1)),
          _cache[37] || (_cache[37] = _createElementVNode("div", { class: "vertical-spacer" }, null, -1)),
          _cache[38] || (_cache[38] = _createElementVNode("div", { class: "api-key-instruction" }, [
            _createTextVNode(" Get your Key from "),
            _createElementVNode("a", {
              href: "https://platform.openai.com/settings/organization/api-keys",
              target: "_blank",
              class: "google-ai-link"
            }, "Google OpenAI API Keys Page")
          ], -1)),
          _createElementVNode("div", null, [
            _cache[35] || (_cache[35] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("form", {
              class: "chirp-search-form-wrapper",
              style: {"grid-row-gap":"0"},
              onSubmit: _cache[22] || (_cache[22] = (e) => {
          e.preventDefault()
          _ctx.initAi()
        })
            }, [
              _cache[34] || (_cache[34] = _createElementVNode("div", { class: "dropdown-label" }, "OpenAI API Key:", -1)),
              _createElementVNode("div", _hoisted_36, [
                _withDirectives(_createElementVNode("input", {
                  class: "chirp-search-input w-input",
                  placeholder: "OpenAI API Key",
                  type: "text",
                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.apiKey) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.apiKey]
                ]),
                _cache[33] || (_cache[33] = _createElementVNode("button", {
                  class: "btn save",
                  type: "submit"
                }, " Refresh ", -1))
              ])
            ], 32)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}