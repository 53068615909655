import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "tags-div" }
const _hoisted_2 = { class: "script-tags-box" }
const _hoisted_3 = { class: "script-tags-toggle" }
const _hoisted_4 = { class: "script-tag-actions-wrapper" }
const _hoisted_5 = { class: "scripts-tags-form" }
const _hoisted_6 = { class: "script-tag-actions-wrapper" }
const _hoisted_7 = { class: "scripts-tags-form" }
const _hoisted_8 = { class: "script-tags-box-btn-wrapper" }
const _hoisted_9 = {
  key: 0,
  class: "code-upload-loading-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScriptTagOutput = _resolveComponent("ScriptTagOutput")!
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "script-tags-box-title" }, [
          _createElementVNode("strong", null, "Publish")
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(["script-tag-toggle-btn", { 'selected-dev': _ctx.selectedEnv == 'dev' }]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedEnv = 'dev'))
          }, " Development Scripts", 2),
          _createElementVNode("div", {
            class: _normalizeClass(["script-tag-toggle-btn", { 'selected': _ctx.selectedEnv == 'prod' }]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedEnv = 'prod'))
          }, " Production Scripts", 2)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["scripts-form-block dev w-form", { open: _ctx.selectedEnv == 'dev' }])
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: "btn move-to-dev",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.publishToDev && _ctx.publishToDev(...args)))
            }, _cache[5] || (_cache[5] = [
              _createElementVNode("div", { class: "normal-icon" }, "done", -1),
              _createElementVNode("div", { class: "btn-text" }, "Publish to Development", -1)
            ]))
          ]),
          _createElementVNode("form", _hoisted_5, [
            _createVNode(_component_ScriptTagOutput, {
              env: 'dev',
              lang: 'html'
            }),
            _createVNode(_component_ScriptTagOutput, {
              env: 'dev',
              lang: 'css'
            }),
            _createVNode(_component_ScriptTagOutput, {
              env: 'dev',
              lang: 'js'
            })
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["scripts-form-block prod w-form", { open: _ctx.selectedEnv == 'prod' }])
        }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              class: "btn move-to-prod",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.moveToProd && _ctx.moveToProd(...args)))
            }, _cache[6] || (_cache[6] = [
              _createElementVNode("div", { class: "normal-icon" }, "done", -1),
              _createElementVNode("div", { class: "btn-text" }, "Move to Production", -1)
            ]))
          ]),
          _createElementVNode("form", _hoisted_7, [
            _createVNode(_component_ScriptTagOutput, {
              env: 'prod',
              lang: 'html'
            }),
            _createVNode(_component_ScriptTagOutput, {
              env: 'prod',
              lang: 'css'
            }),
            _createVNode(_component_ScriptTagOutput, {
              env: 'prod',
              lang: 'js'
            })
          ])
        ], 2),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", {
            class: "notification-btn",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setShowScriptTags(false)))
          }, "Hide")
        ]),
        (_ctx.showLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[7] || (_cache[7] = [
              _createElementVNode("div", { class: "code-upload-loading" }, null, -1),
              _createElementVNode("div", { class: "text-center" }, "Publishing", -1)
            ])))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.showNotification)
      ? (_openBlock(), _createBlock(_component_Notification, {
          key: 0,
          options: _ctx.notificationOptions
        }, null, 8, ["options"]))
      : _createCommentVNode("", true)
  ], 64))
}