import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "left-panel-list project"
}
const _hoisted_2 = { class: "version-tab-head" }
const _hoisted_3 = { class: "selected-version-details-wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "selected-version-details-col"
}
const _hoisted_5 = { class: "selected-version-name" }
const _hoisted_6 = { class: "chirp-result-author" }
const _hoisted_7 = { class: "chirp-result-details" }
const _hoisted_8 = {
  key: 1,
  class: "selected-version-details-col"
}
const _hoisted_9 = { class: "selected-version-actions-col" }
const _hoisted_10 = {
  key: 0,
  class: "selected-version-details-wrapper deleting"
}
const _hoisted_11 = { class: "selected-version-details-col" }
const _hoisted_12 = { class: "version-item-text" }
const _hoisted_13 = { class: "selected-version-actions-col deleteing" }
const _hoisted_14 = {
  key: 0,
  class: "app-loading-div"
}
const _hoisted_15 = {
  key: 1,
  class: "selected-version-details-wrapper"
}
const _hoisted_16 = { class: "selected-version-details-col" }
const _hoisted_17 = {
  key: 0,
  class: "selected-version-name"
}
const _hoisted_18 = {
  key: 1,
  class: "chirp-result-author"
}
const _hoisted_19 = { class: "selected-version-actions-col" }
const _hoisted_20 = {
  key: 0,
  class: "app-loading-div"
}
const _hoisted_21 = { class: "versions-list" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { class: "version-item-text title" }
const _hoisted_24 = { class: "version-item-text sub" }
const _hoisted_25 = { class: "version-item-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.selectedProject)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.selectedVersion)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.selectedVersion.number), 1),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(new Date(_ctx.selectedVersion.created).toLocaleDateString()) + " - " + _toDisplayString(new Date(_ctx.selectedVersion.created).toLocaleTimeString()), 1),
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.selectedVersion.comments), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[10] || (_cache[10] = [
                    _createElementVNode("div", { class: "selected-version-name" }, "Current Code", -1),
                    _createElementVNode("div", { class: "chirp-result-author" }, "Right Now", -1),
                    _createElementVNode("div", { class: "chirp-result-details" }, "...", -1)
                  ]))),
              _createElementVNode("div", _hoisted_9, [
                (!_ctx.selectedVersion && !_ctx.showVersionInput)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "btn version-action",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.selectedVersion = null; _ctx.showVersionInput = true;})
                    }, " Save "))
                  : _createCommentVNode("", true),
                (_ctx.selectedVersion)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: "btn version-action green",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.restoreVersion()))
                    }, " Restore "))
                  : _createCommentVNode("", true),
                (_ctx.selectedVersion)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: "btn version-action red",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.areDeletingVersion = true))
                    }, " Delete "))
                  : _createCommentVNode("", true)
              ])
            ]),
            (_ctx.areDeletingVersion && _ctx.selectedVersion)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _cache[11] || (_cache[11] = _createTextVNode(" Are you sure you want to remove version number ")),
                      _createElementVNode("strong", null, _toDisplayString(_ctx.selectedVersion.number), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", {
                      class: "btn version-action red",
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleDeleteVersion && _ctx.handleDeleteVersion(...args)))
                    }, " Yes "),
                    _createElementVNode("div", {
                      class: "btn version-action gray",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.areDeletingVersion = false))
                    }, " Cancel ")
                  ]),
                  (_ctx.areDeletingVersionLoading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[12] || (_cache[12] = [
                        _createElementVNode("div", { class: "app-loading-animation" }, null, -1)
                      ])))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.showVersionInput)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    (_ctx.selectedVersion)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.selectedVersion.number), 1))
                      : _createCommentVNode("", true),
                    (_ctx.selectedVersion)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_ctx.selectedVersion.created), 1))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      class: "w-form",
                      onSubmit: _cache[6] || (_cache[6] = (e) => {
            e.preventDefault();
            _ctx.handleSaveVersion(_ctx.versionComments);
          }
            )
                    }, [
                      _createElementVNode("form", null, [
                        _withDirectives(_createElementVNode("textarea", {
                          placeholder: "Comment",
                          class: "version-comment-input w-input",
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.versionComments) = $event))
                        }, null, 512), [
                          [_vModelText, _ctx.versionComments]
                        ])
                      ])
                    ], 32)
                  ]),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", {
                      class: "btn version-action",
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.handleSaveVersion(_ctx.versionComments)))
                    }, " Save "),
                    _createElementVNode("div", {
                      class: "btn version-action gray",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showVersionInput = false))
                    }, " Cancel ")
                  ]),
                  (_ctx.saveVersionLoading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_20, _cache[13] || (_cache[13] = [
                        _createElementVNode("div", { class: "app-loading-animation" }, null, -1)
                      ])))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", {
              class: _normalizeClass(["version-item current", { selected: !_ctx.selectedVersion }]),
              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.setSelectedVersion(null)))
            }, _cache[14] || (_cache[14] = [
              _createElementVNode("div", { class: "version-item-text title" }, "Current Work", -1),
              _createElementVNode("div", { class: "version-item-text sub" }, "Right Now", -1)
            ]), 2),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.versions, (version) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["version-item", { selected: _ctx.selectedVersion && _ctx.selectedVersion.number == version.number, }]),
                key: version.number,
                onClick: ($event: any) => {
        if (_ctx.selectedVersion && _ctx.selectedVersion.number == version.number) {
        } else {
          _ctx.setSelectedVersion(version)
        }

          _ctx.showVersionInput = false;

          }
              }, [
                _createElementVNode("div", _hoisted_23, _toDisplayString(version.number), 1),
                _createElementVNode("div", _hoisted_24, _toDisplayString(new Date(version.created).toLocaleDateString()) + " - " + _toDisplayString(new Date(version.created).toLocaleTimeString()), 1),
                _createElementVNode("div", _hoisted_25, _toDisplayString(version.comments), 1),
                (version.id == _ctx.selectedProject.prodVersion || version.id == _ctx.selectedProject.devVersion)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["icon", {
            'prod-version': version.id == _ctx.selectedProject.prodVersion,
            'dev-version': version.id == _ctx.selectedProject.devVersion,
            'dev-prod-version': version.id == _ctx.selectedProject.prodVersion && version.id == _ctx.selectedProject.devVersion
          }])
                    }, "rocket_launch", 2))
                  : _createCommentVNode("", true)
              ], 10, _hoisted_22))
            }), 128)),
            _cache[15] || (_cache[15] = _createElementVNode("div", { class: "versions-list-load-wrapper" }, [
              _createElementVNode("div", { class: "btn version-action load-more hidden" }, "Load More")
            ], -1))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showNotification)
      ? (_openBlock(), _createBlock(_component_Notification, {
          key: 1,
          options: _ctx.notificationOptions
        }, null, 8, ["options"]))
      : _createCommentVNode("", true)
  ], 64))
}