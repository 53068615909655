import { createStore } from "vuex";
import modLogin from "./modLogin";
import modProjects from "./modProjects";
import modChirp from "./modChirp";
import modVersioning from "./modVersioning";

export default createStore({
  state: {
    projectMenuTab: "",
    showNotification: false,
    workspaceNav: "projects",
    appIsLoading: false,
    showPreview: true,
    htmlEditorView: true,
    cssEditorView: true,
    jsEditorView: true,
    previewKey: 0,
    aiProvider: "Local Ollama"
  },
  getters: {},
  mutations: {
    setAiProvider(state, value){
      state.aiProvider = value
    },
    updatePreview(state, value){
      state.previewKey += 1
    },
    setProjectMenuTab(state, tab){
      if(tab != state.projectMenuTab){
        state.projectMenuTab = tab
      } else {
        state.projectMenuTab = ''
      }
      
    },
    toggleJsEditor(state){
      state.jsEditorView = !state.jsEditorView;
    },
    toggleHtmlEditor(state){
      state.htmlEditorView = !state.htmlEditorView;
    },
    toggleCssEditor(state){
      state.cssEditorView = !state.cssEditorView;
    },
    setAppIsLoading(state, value) {
      state.appIsLoading = value;
    },
    setShowNotification(state, value) {
      state.showNotification = value;
    },
    setWorkspaceNav(state, value) {
      state.workspaceNav = value;
    },
    refreshPreview(state) {
      state.showPreview = false,
        setTimeout(() => {
          state.showPreview = true
        }, 50)
    }
  },
  actions: {},
  modules: {
    modLogin,
    modProjects,
    modChirp,
    modVersioning,
  },
});
